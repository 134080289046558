import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchLeadData = createAsyncThunk(
  'Lead/fetchLeadData',
  async ({ overAllScore, CodeQualityScore, MetadataScore, SecurityScore }) => {
    const guid = window.sessionStorage.getItem('guid');
    try {
      const response = await fetch(`/lead/${guid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          overAllScore,
          codeQuality: CodeQualityScore,
          metadata: MetadataScore,
          security: SecurityScore,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch lead data');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error('Failed to fetch lead data: ' + error.message);
    }
  }
);
