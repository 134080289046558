import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminData = createAsyncThunk(
  "admin/fetchAdminData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        `/v1/salesforce/admin/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
      //   console.log('fetchOrgLimitData',data);
      return data;
    }
  
);

export const fetchAdminDataFromMongo = createAsyncThunk(
  "admin/fetchAdminDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/admin/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      //   console.log('fetchOrgLimitData',data);
      return data;
    }
  
);